<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<distressalertssitrep-form-header :modelPk="modelPk"></distressalertssitrep-form-header>
		</pui-form-header>
		<distressalertssitrep-modals :modelName="modelName"></distressalertssitrep-modals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#main'">{{ $t('distressalertssitrep.maintab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="'main'">
					<v-row class="pui-form-layout">
						<v-col cols="12">
							<v-row dense>
								<!-- SITREPNUMBER -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" v-if="!isCreatingElement">
									<pui-number-field
										:id="`sitrepnumber-distressalertssitrep`"
										v-model="model.sitrepnumber"
										:label="$t('distressalertssitrep.sitrepnumber')"
										disabled
										required
										toplabel
										integer
										min="0"
										max="99999"
									></pui-number-field>
								</v-col>
								<!-- DATE -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
									<pui-date-field
										:id="`date-distressalertssitrep`"
										v-model="model.date"
										:label="$t('distressalertssitrep.date')"
										:disabled="this.disabled"
										required
										toplabel
										time
										:max="new Date()"
									></pui-date-field>
								</v-col>

								<!-- FROM -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
									<pui-text-field
										:id="`from-distressalertssitrep`"
										v-model="model.fromsender"
										:label="$t('distressalertssitrep.from')"
										:disabled="this.disabled"
										required
										toplabel
										maxlength="100"
									></pui-text-field>
								</v-col>

								<!-- TO -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
									<pui-text-field
										:id="`to-distressalertssitrep`"
										v-model="model.toreceiver"
										:label="$t('distressalertssitrep.to')"
										:disabled="this.disabled"
										required
										toplabel
										maxlength="100"
									></pui-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<!-- ASSISTANCEREQUIRED -->
								<v-col class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
									<pui-select
										:id="`assistancerequired-distressalertssitrep`"
										:attach="`assistancerequired-distressalertssitrep`"
										:label="$t('distressalertssitrep.assistancerequired')"
										itemText="text"
										itemValue="value"
										:disabled="this.disabled"
										required
										toplabel
										v-model="model"
										:items="codes"
										reactive
										:itemsToSelect="assistancerequiredItemsToSelect"
										:modelFormMapping="{ value: 'assistancerequired' }"
									></pui-select>
								</v-col>
								<!-- NUMPERSONSRISK -->
								<v-col class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
									<pui-number-field
										:id="`numpersonsrisk-distressalertssitrep`"
										v-model="model.numpersonsrisk"
										:label="$t('distressalertssitrep.numpersonsrisk')"
										:disabled="this.disabled"
										required
										toplabel
										integer
										min="0"
										max="99999"
									></pui-number-field>
								</v-col>
								<!-- POSITION -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
									<pui-text-field
										:id="`position-distressalertssitrep`"
										v-model="model.position"
										:label="$t('distressalertssitrep.position')"
										:disabled="this.disabled"
										required
										toplabel
										maxlength="100"
									></pui-text-field>
								</v-col>
								<!-- COORDINATINGMRCC -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
									<pui-text-field
										:id="`coordinatingmrcc-distressalertssitrep`"
										v-model="model.coordinatingmrcc"
										:label="$t('distressalertssitrep.coordinatingmrcc')"
										:disabled="this.disabled"
										required
										toplabel
									></pui-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<!-- SITUATION -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<pui-text-area
										:id="`situation-distressalertssitrep`"
										v-model="model.situation"
										:label="$t('distressalertssitrep.situation')"
										:disabled="this.disabled"
										required
										toplabel
										maxlength="256"
									></pui-text-area>
								</v-col>
								<!-- DESCOFCASUALTY -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<pui-text-area
										:id="`descofcasualty-distressalertssitrep`"
										v-model="model.descofcasualty"
										:label="$t('distressalertssitrep.descofcasualty')"
										:disabled="this.disabled"
										required
										toplabel
									></pui-text-area>
								</v-col>
							</v-row>
							<v-row dense>
								<!-- WEATHER -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<pui-text-area
										:id="`weather-distressalertssitrep`"
										v-model="model.weather"
										:label="$t('distressalertssitrep.weather')"
										:disabled="this.disabled"
										required
										toplabel
									></pui-text-area>
								</v-col>
								<!-- INITIALACTIONS -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<pui-text-area
										:id="`initialactions-distressalertssitrep`"
										v-model="model.initialactions"
										:label="$t('distressalertssitrep.initialactions')"
										:disabled="this.disabled"
										required
										toplabel
									></pui-text-area>
								</v-col>
							</v-row>
							<v-row dense>
								<!-- SEARCHAREA -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<pui-text-area
										:id="`searcharea-distressalertssitrep`"
										v-model="model.searcharea"
										:label="$t('distressalertssitrep.searcharea')"
										:disabled="this.disabled"
										required
										toplabel
									></pui-text-area>
								</v-col>
								<!-- COORDINATINGINSTRUCTIONS -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<pui-text-area
										:id="`coordinatinginstructions-distressalertssitrep`"
										v-model="model.coordinatinginstructions"
										:label="$t('distressalertssitrep.coordinatinginstructions')"
										:disabled="this.disabled"
										required
										toplabel
									></pui-text-area>
								</v-col>
							</v-row>
							<v-row dense>
								<!-- FUTUREPLANS -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<pui-text-area
										:id="`futureplans-distressalertssitrep`"
										v-model="model.futureplans"
										:label="$t('distressalertssitrep.futureplans')"
										:disabled="this.disabled"
										required
										toplabel
									></pui-text-area>
								</v-col>
								<!-- ADDITIONAL -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<pui-text-area
										:id="`additional-distressalertssitrep`"
										v-model="model.additional"
										:label="$t('distressalertssitrep.additional')"
										:disabled="this.disabled"
										required
										toplabel
									></pui-text-area>
								</v-col>
							</v-row>
							<v-row dense>
								<!-- CARGOADDRESS -->
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-text-area
										:id="`cargoaddress-distressalertssitrep`"
										v-model="model.cargoaddress"
										:label="$t('distressalertssitrep.cargoaddress')"
										:disabled="this.disabled"
										required
										toplabel
									></pui-text-area>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-tab-item>
			</v-tabs-items>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="this.disabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import distressalertssitrepActions from './DistressalertssitrepActions';
import distressalertssitrepModals from './DistressalertssitrepModals.vue';

export default {
	name: 'distressalertssitrep-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'distressalertssitrep-modals': distressalertssitrepModals
	},
	data() {
		return {
			modelName: 'distressalertssitrep',
			actions: distressalertssitrepActions.formactions,
			parentModelName: 'distressalerts',
			disabled: true,
			codes: [
				{
					text: this.$t('imofalform3items.yes'),
					value: 1
				},
				{
					text: this.$t('imofalform3items.no'),
					value: 0
				}
			]
		};
	},
	methods: {
		afterGetData() {
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel) {
				this.model.distressid = parentModel.pk.distressid;
			}
			const opts = {
				model: 'distressalerts',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'distressid',
							op: 'eq',
							data: this.model.distressid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data) {
					if (response.data.data[0].statuscode == 'C') {
						this.disabled = true;
					} else {
						this.disabled = false;
					}
				}
			});
		}
	},

	computed: {
		assistancerequiredItemsToSelect() {
			return this.codes.filter((code) => code.value === this.model.assistancerequired);
		}
	},
	created() {},

	watch: {
		'model.assistancerequired': function (newVal) {
			this.model.assistancerequired = Number(newVal);
		}
	}
};
</script>
